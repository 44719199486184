export default [
  {
    path: '',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '~/views/Users/UsersTable.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUsers' },
    },
  },

];
